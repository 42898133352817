@font-face {
  font-family: 'deltafire-icons';
  src: url('deltafire-icons.eot?30143740');
  src: url('deltafire-icons.eot?30143740#iefix') format('embedded-opentype'),
       url('deltafire-icons.woff2?30143740') format('woff2'),
       url('deltafire-icons.woff?30143740') format('woff'),
       url('deltafire-icons.ttf?30143740') format('truetype'),
       url('deltafire-icons.svg?30143740#deltafire-icons') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'deltafire-icons';
    src: url('../font/deltafire-icons.svg?30143740#deltafire-icons') format('svg');
  }
}
*/
i[class^="df-"]:before, i[class*=" df-"]:before {
  font-family: "deltafire-icons";
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

i.df-alarm-manual:before { content: '\e800'; } /* '' */
i.df-alarm-reset:before { content: '\e801'; } /* '' */
i.df-alarm-smoke:before { content: '\e802'; } /* '' */
i.df-alarm-thermo:before { content: '\e803'; } /* '' */
i.df-alarm-return:before { content: '\e804'; } /* '' */
i.df-alert-brigade:before { content: '\e805'; } /* '' */
i.df-modem-off:before { content: '\e806'; } /* '' */
i.df-alert-remove:before { content: '\e807'; } /* '' */
i.df-amonia:before { content: '\e808'; } /* '' */
i.df-dashboard:before { content: '\e80b'; } /* '' */
i.df-desktop:before { content: '\e80c'; } /* '' */
i.df-equipaments:before { content: '\e80d'; } /* '' */
i.df-events-none:before { content: '\e80e'; } /* '' */
i.df-hydrant:before { content: '\e80f'; } /* '' */
i.df-modem:before { content: '\e811'; } /* '' */
i.df-monitor-access:before { content: '\e812'; } /* '' */
i.df-monitor-energy:before { content: '\e813'; } /* '' */
i.df-monitor-flow:before { content: '\e814'; } /* '' */
i.df-monitor-pression:before { content: '\e815'; } /* '' */
i.df-panel:before { content: '\e816'; } /* '' */
i.df-relatories:before { content: '\e817'; } /* '' */
i.df-reports:before { content: '\e818'; } /* '' */
i.df-router:before { content: '\e819'; } /* '' */
i.df-system:before { content: '\e81a'; } /* '' */
i.df-thermo-high:before { content: '\e81c'; } /* '' */
i.df-ac-error:before { content: '\e81d'; } /* '' */
i.df-ac-off:before { content: '\e81e'; } /* '' */
i.df-thermo-low:before { content: '\e81f'; } /* '' */
i.df-alarm-abandon:before { content: '\e820'; } /* '' */
i.df-alarm-abandon-on:before { content: '\e821'; } /* '' */
i.df-alarm-brigade:before { content: '\e822'; } /* '' */
i.df-alarm-fire:before { content: '\e823'; } /* '' */
i.df-alarm-gas:before { content: '\e824'; } /* '' */
i.df-linear-error:before { content: '\e825'; } /* '' */
i.df-battery-low:before { content: '\e826'; } /* '' */
i.df-alarm-visual:before { content: '\e827'; } /* '' */
i.df-alert-mobile:before { content: '\e828'; } /* '' */
i.df-control-ambiental:before { content: '\e829'; } /* '' */
i.df-thermo-control:before { content: '\e82a'; } /* '' */
