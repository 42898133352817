@font-face {
    font-family: 'SF Pro Light';
    src: url('SFProText-Light.woff2') format('woff2'),
        url('SFProText-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SF Pro';
    src: url('SFProDisplay-Regular.woff2') format('woff2'),
        url('SFProDisplay-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

